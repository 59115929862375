import React, { useEffect } from "react";
import "./style.css";
import { sprt } from "./spritz_util.js";

import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const Spritz = () => {
  const [text, setText] = React.useState("");
  const [speed, setSpeed] = React.useState(200);
  const [readingTime, setReadingTime] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handlePaste = () => {
    // directly past from the clipboard
    navigator.clipboard.readText().then((text) => {
      setText(text);
      const wordsPerMinute = 200; // Average reading speed
      const words = text.split(" ").length;
      setReadingTime(Math.ceil(words / wordsPerMinute));
    });

    setIsPlaying(false);
    sprt.stopSpritz();
    sprt.spritz(text, speed);
  };

  const handleStart = () => {
    setIsPlaying(true);
    sprt.startSpritz();
  };

  const handleStop = () => {
    setIsPlaying(false);
    sprt.stopSpritz();
  };

  useEffect(() => {
    if (isPlaying) {
      sprt.stopSpritz();
      sprt.startSpritz(speed);
    }
    return () => {};
  }, [speed]);

  return (
    <>
      <div className="mb-3 min-h-[166px] w-[800px] rounded-md bg-white py-3 shadow-md">
        <div id="spritz_container">
          <div id="guide_top">
            ――――――――――<span id="notch">&#1092;</span>―――――――――――
          </div>

          <div id="spritz_result">Choose a WPM to start.</div>

          <div id="guide_bottom">――――――――――――――――――――――</div>

          <div className="flex h-8 w-full items-center justify-center gap-2 rounded-md bg-gray-500 px-2 text-center">
            <ContentPasteIcon
              className="cursor-pointer"
              onClick={handlePaste}
            />

            <FastRewindIcon
              className="cursor-pointer"
              onClick={() => setSpeed(speed - 10)}
            />
            {
              // play pause button  logic
              !isPlaying ? (
                <PlayArrowIcon
                  className="cursor-pointer"
                  onClick={handleStart}
                />
              ) : (
                <StopIcon className="cursor-pointer" onClick={handleStop} />
              )
            }
            <FastForwardIcon
              className="cursor-pointer"
              onClick={() => setSpeed(speed + 10)}
            />
            {/* play pause button  logic */}

            <select
              defaultValue={"200"}
              id="spritz_selector"
              className="h-6  cursor-pointer bg-gray-500 text-sm text-white"
              onChange={(e) => setSpeed(parseInt(e.target.value))}
            >
              <option value="0">Select WPM</option>
              <option value="200">200wpm</option>
              <option value="300">300wpm</option>
              <option value="350">350wpm</option>
              <option value="400">400wpm</option>
              <option value="450">450wpm</option>
              <option value="500">500wpm</option>
              <option value="550">550wpm</option>
              <option value="600">600wpm</option>
              <option value="650">650wpm</option>
              <option value="700">700wpm</option>
              <option value="750">750wpm</option>
              <option value="800">800wpm</option>
              <option value="850">850wpm</option>
              <option value="900">900wpm</option>
              <option value="950">950wpm</option>
            </select>
            <div>{readingTime} mins</div>
          </div>
        </div>
      </div>

      <div id="spritz_spacer"></div>
    </>
  );
};

export default Spritz;
