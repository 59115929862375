// tasks sorter panel, its a ui componnets which lets users select sorting paramerters for tasks like task lenght, schedule date, expiry or priority

import React, { useEffect } from "react";
import { TaskSchema } from "../../type";
import { get_date } from "../../components/utils/general_utils";

type Props = {
  onFilterNSort: (filter: Metrics, sort: Metrics) => void;
};

type Metrics = {
  [key: string]: boolean;
};
const _sorterMetrics: Metrics = {
  task_length: true,
  date_scheduled: true,
  date_expire: true,
  priority: true,
};

const _filterMetrics: Metrics = {
  All: true,
  Today: false,
  Due: false,
  Tomorrow: false,
};

export const TaskSorterPanel = ({ onFilterNSort }: Props) => {
  const [sorterMetrics, setSorterMetrics] = React.useState(_sorterMetrics);
  const [filterMetrics, setFilterMetrics] = React.useState(_sorterMetrics);

  useEffect(() => {
    onFilterNSort(filterMetrics, sorterMetrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMetrics, sorterMetrics]);

  return (
    <div className="flex flex-col items-center justify-center gap-1 rounded-md bg-white p-1">
      <div className="flex items-center justify-center gap-1">
        {Object.keys(_filterMetrics).map((key) => {
          return (
            <div
              className={` cursor-pointer rounded  px-2 ${
                filterMetrics[key] ? "bg-green-400" : ""
              }`}
              onClick={() =>
                setFilterMetrics({
                  ...filterMetrics,
                  [key]: !filterMetrics[key],
                })
              }
            >
              {key}
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-center gap-1">
        {Object.keys(_sorterMetrics).map((key) => {
          return (
            <div
              className={` cursor-pointer rounded  px-2 ${
                sorterMetrics[key] ? "bg-green-400" : ""
              }`}
              onClick={() =>
                setSorterMetrics({
                  ...sorterMetrics,
                  [key]: !sorterMetrics[key],
                })
              }
            >
              {key}
            </div>
          );
        })}
      </div>
    </div>
  );
};

// sorterMetrics is an object that contains the metrics that are used to sort the tasks
//  consider all the metrics which are true for sorting the tasks
//  if the metric is false, it will not be considered for sorting the tasks

// sort the tasks by task_lenght, date_scheduled, date_expire, priority and availibility of time
// all above fields are not mandatory, so we need to check if they are present or not
// tasks get sorted for the next 24 hours. If the task is not completed in 24 hours, it will be rescheduled
export const sortTasks = (
  tasks: TaskSchema[],
  sorterMetrics: Metrics,
): TaskSchema[] => {
  if (sorterMetrics === undefined) sorterMetrics = _sorterMetrics;

  const compareTasks = (a: TaskSchema, b: TaskSchema): number => {
    let comparison = 0;

    if (sorterMetrics.priority && a.priority !== b.priority) {
      comparison = b.priority - a.priority; // Higher priority first
      if (comparison !== 0) {
        return comparison;
      }
    }

    if (sorterMetrics.date_scheduled && a.date_scheduled && b.date_scheduled) {
      comparison =
        new Date(a.date_scheduled).getTime() -
        new Date(b.date_scheduled).getTime();
      if (comparison !== 0) {
        return comparison;
      }
    }

    if (sorterMetrics.date_expire && a.date_expire && b.date_expire) {
      if (a.date_expire && b.date_expire) {
        comparison =
          new Date(a.date_expire).getTime() - new Date(b.date_expire).getTime();
        if (comparison !== 0) {
          return comparison;
        }
      }
    }

    if (sorterMetrics.task_length && a.task_length !== b.task_length) {
      comparison = a.task_length - b.task_length; // Shorter tasks first
      if (comparison !== 0) {
        return comparison;
      }
    }

    return 0; // If all metrics are equal, maintain original order
  };

  const sortedTasks = tasks
    .filter((t) => !t.status) // Filter out completed tasks
    .slice()
    .sort(compareTasks); // Create a shallow copy to avoid mutating the original array

  return sortedTasks;
};

// filter the tasks based on the metrics

export const filterTasks = (
  tasks: TaskSchema[],
  filterMetrics: Metrics,
): TaskSchema[] => {
  let filterTasks = [];
  if (filterMetrics === undefined) filterMetrics = _filterMetrics;

  filterTasks = tasks.filter((task) => {
    if (filterMetrics.All) return true;
    if (filterMetrics.Today) {
      if (get_date(task.date_expire) === get_date(Date.now())) return true;
    }
    if (filterMetrics.Tomorrow) {
      if (
        get_date(task.date_expire) ===
        get_date(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
      )
        return true;
    }
    if (filterMetrics.Due) {
      if (
        new Date(task.date_expire) <
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000) &&
        !task.status
      )
        return true;
    }
    return false;
  });

  return filterTasks;
};
