import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleOAuthProvider,
  googleLogout,
  GoogleLogin,
} from "@react-oauth/google";
import jwt_decode from "jwt-decode";

import { Login, vinanti } from "../../data/data";
import {
  toggleLogin,
  userInfo,
  updateCustomSettings,
  StateUpdateAction,
  selectPage,
} from "../../store/actions";
import { Button } from "../utils/button";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { TypePayloadAction } from "../../type";

const CLIENT_ID =
  "374169744841-5bhijqlfbjmca09soll74o4k3j3d7jvb.apps.googleusercontent.com";

const dummy_user = {
  user_id: "guest",
  img_url: "https://www.flaticon.com/premium-icon/guests_3044552",
  user_name: "Guest",
  user_email: "email",
  auth_token: "auth",
};

export const LoginTab = () => {
  const _user = useAppSelector((state) => state.user_info);
  const [isOpen, setIsOpen] = useState(false);

  const [user, setUser] = useState(_user || dummy_user);

  const loggedIn = useAppSelector((state) => state.log_in_status);
  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const log_in = useCallback(
    (userObject: any) => {
      if (!userObject) return;
      const { name, sub, picture, email } = userObject;
      // login to admin io with google credits
      Login({ name, sub, picture, email }).then(async (res) => {
        if (res.data.login === "Success") {
          dispatch_action({ type: "SIGN_IN", payload: true });

          var payload = {
            user_id: sub,
            img_url: picture,
            user_name: name,
            user_email: email,
            auth_token: res.data["auth-token"],
          };

          dispatch(userInfo(payload));

          var meta_data = await vinanti("", "metadata", payload.auth_token, {
            email: payload.user_email,
          });

          dispatch(
            StateUpdateAction(
              "META_DATA",
              meta_data && meta_data.length > 0 ? meta_data[0] : null,
            ),
          );

          var custom_setts = await vinanti(
            "list",
            "custom",
            payload.auth_token,
            {
              email: payload.user_email,
            },
          );
          dispatch(updateCustomSettings(custom_setts?.[0]));
        }
      });
    },
    [dispatch_action, dispatch],
  );

  const onSignIn = (googleUser: any) => {
    const userObject: any = jwt_decode(googleUser.credential);
    localStorage.setItem("user", JSON.stringify(userObject));
    // console.log("userObject", userObject);
    dispatch_action({ type: "SIGN_IN", payload: true });
    log_in(userObject);
  };

  const signOut = () => {
    localStorage.setItem("user", "null");
    dispatch(toggleLogin(false)); // this.setState({ loggedIn: true });
    var payload = {
      ...dummy_user,
    };
    dispatch(userInfo(payload));
    googleLogout();
  };

  useEffect(() => {
    if (_user) setUser(_user);
    else setUser(dummy_user);
  }, [_user]);

  useEffect(() => {
    const storedValue = localStorage.getItem("user");
    if (storedValue !== null) {
      log_in(JSON.parse(storedValue));
    }
    return () => {};
  }, [log_in]);

  return (
    <>
      {loggedIn ? (
        <>
          <div
            className="flex h-10 cursor-pointer items-center justify-center"
            onClick={toggleCollapse}
          >
            <div className="hidden p-3 text-white md:visible lg:flex">
              {user.user_name}
            </div>
            <img
              src={user.img_url}
              className="h-10 rounded-full p-0"
              alt={user.user_name}
            />
          </div>
          {isOpen ? (
            <div className="absolute right-0 top-12 z-20 w-auto rounded-lg bg-white p-2 shadow-lg">
              <div className="flex flex-col items-center justify-center ">
                <Button
                  text="Settings"
                  icon={"settings"}
                  onclick={() =>
                    dispatch(selectPage("Settings")) && toggleCollapse()
                  }
                />
                <Button text="Logout" onclick={signOut} icon={"logout"} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <GoogleOAuthProvider clientId={`${CLIENT_ID}`}>
          <GoogleLogin
            onSuccess={onSignIn}
            // onFailure={signOut}
            // cookiePolicy="single_host_origin"
            auto_select={true}
            theme="filled_black"
            text="continue_with"
            useOneTap={true}
            size="small"
          />
        </GoogleOAuthProvider>
      )}
    </>
  );
};
