import { Icon } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import SpeedIcon from "@mui/icons-material/Speed";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const TypeComponent: React.FC = () => {
  const [text, setText] = useState("");
  const [speed, setSpeed] = useState(55);

  const [readingTime, setReadingTime] = useState(0);
  const typedRef = useRef<Typed | null>(null);
  const el = useRef<HTMLDivElement | null>(null);

  const prettyLog = (message: string) => {
    console.log("%c" + message, "color: green; font-weight: bold;");
  };

  const handleStart = () => {
    if (typedRef.current) {
      typedRef.current.start();
    }
  };

  const handleStop = () => {
    if (typedRef.current) {
      typedRef.current.stop();
    }
  };
  useEffect(() => {
    if (typedRef.current) {
      typedRef.current.destroy();
    }

    if (el.current) {
      typedRef.current = new Typed(el.current, {
        strings: [text],
        typeSpeed: speed,
        backSpeed: speed,
        loop: false,
      });
    }

    return () => {
      if (typedRef.current) {
        typedRef.current.destroy();
      }
    };
  }, [text, speed]);

  const handlePaste = () => {
    // directly past from the clipboard
    navigator.clipboard.readText().then((text) => {
      setText(text);
      const wordsPerMinute = 200; // Average reading speed
      const words = text.split(" ").length;
      setReadingTime(Math.ceil(words / wordsPerMinute));
    });
  };

  return (
    <div className="flex flex-col items-center justify-center rounded-lg bg-white p-2 shadow-lg">
      <div className="flex h-8 w-fit items-center justify-center gap-2 rounded-md bg-gray-500 px-2 text-center">
        <ContentPasteIcon className="cursor-pointer" onClick={handlePaste} />

        <FastRewindIcon
          className="cursor-pointer"
          onClick={() => setSpeed(speed - 10)}
        />
        <PlayArrowIcon className="cursor-pointer" onClick={handleStart} />
        <StopIcon className="cursor-pointer" onClick={handleStop} />
        <FastForwardIcon
          className="cursor-pointer"
          onClick={() => setSpeed(speed + 10)}
        />
        {/* play pause button  logic */}

        <div>{speed} speed </div>
        <div>{readingTime} mins</div>
      </div>
      <div ref={el}></div>
    </div>
  );
};

export default TypeComponent;
