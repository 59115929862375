import React, {  } from "react";
import { vinanti } from "../../data/data";
import { useDispatch, useSelector } from "react-redux";
import { updateUsersList } from "../../store/actions";
import { Button } from "react-utility-yard";
import { RootState } from "../../store/store"; // Import RootState for type safety

export function Users() {
  const list = useSelector((state: RootState) => state.users_page_users);
  const dispatch = useDispatch();

  const get_users_List = async () => {
    try {
      const data = await vinanti("list", "users");
      dispatch(updateUsersList(data));
    } catch (error) {
      console.error("Error fetching users list:", error);
    } finally {
    }
  };

  const get_date = (date: string) => {
    const data = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return data.toLocaleDateString("en-US", options);
  };

  // useEffect(() => {
  //   get_users_List();
  // }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl font-bold">Users</h1>
        <Button onclick={get_users_List} text="Get Users" />
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {list && list.length > 0 ? (
          list.map((d: any, i: any) => (
            <div key={i} className="rounded-lg bg-white p-4 shadow-md">
              <div className="flex gap-4">
                <div className="w-24 h-24">
                  <img
                    src={d?.img_url}
                    className="rounded-full object-cover w-full h-full"
                    alt="Profile"
                  />
                </div>
                <div className="flex flex-col justify-between">
                  <h5 className="text-lg font-semibold">{d.name}</h5>
                  <div className="text-sm text-gray-600">{d.google_id}</div>
                  <div className="text-sm text-gray-600">{d.email}</div>
                  <div className="text-sm text-gray-600">
                    {get_date(d.date)}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-500">
            No Data Found -_-'
          </div>
        )}
      </div>
    </div>
  );
}
