import { Component, useEffect, useState } from "react";
import "./App.css";
import { Pagex } from "./pages/index";
import NavTailwind from "./components/nav_bar/nav_bar_tailwind";
// import markdown_file from "./data/README.md";

import bg_Img from "./data/images/dark_color_vivid_bg.png";
import { splash_imgae } from "./data/data";
import { FooterBar } from "./components/footer_bar/footer_bar";
import { useAppSelector } from "./store/store";


export const App = () => {
  const isLogin = useAppSelector((state) => state.log_in_status);

  const [state, setState] = useState<{ bg_img: string }>({
    bg_img: bg_Img,
  });

  const change_wallpaper = () => {
    splash_imgae().then((m) => {
      if (m && m[0]) {
        setState({ ...state, bg_img: m[0]?.urls?.full });
      }
    });
  };  
  
  
  useEffect(() => {
    change_wallpaper();
    
    return () => {};
  }, []);

  return (
    <div key={"MAIN_APP"} className="App">
      <NavTailwind />
      <main
        className="main-wrapper"
        role="main"
        style={{ backgroundImage: `url(${state.bg_img})` }}
      >
        <section id="Admin-Section">
          {isLogin === true ? (
            <>
              <Pagex />
              {/* <CustomRightClick /> */}
            </>
          ) : (
            <div className="login-page flex h-full justify-center overflow-x-scroll bg-white">
              <div>
                {/* <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    children={this.state.readme}
                  /> */}
              </div>
            </div>
          )}
        </section>
      </main>
      <FooterBar change_wallpaper={change_wallpaper} />
    </div>
  );
};

export default App;
