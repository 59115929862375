import React, { useEffect } from "react";
import { Container, Typography, Grid, Paper, Box } from "@mui/material";
// import { UserInfo } from "../../interface";
// import { useAppSelector } from "../../store/store";
import NotificationSettings from "./notification_settings";
import ProfileSettings from "./profile_settings";

const Settings: React.FC = () => {
  // get user info from Redux store
  // const _user = useAppSelector((state) => state.user_info);

  // const [user] = useState<UserInfo | null>(_user);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <Paper elevation={5} sx={{ p: 1, mb: 2 }}>
          <Typography variant="h4" component="h1">
            Settings
          </Typography>
        </Paper>
        <Paper
          elevation={1}
          sx={{ overflow: "auto", maxHeight: "700px", p: 3 }}
        >
          <Grid container spacing={3}>
            <ProfileSettings />
          </Grid>
          <NotificationSettings />
        </Paper>
      </Box>
    </Container>
  );
};

export default Settings;
