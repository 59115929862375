import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { UserInfo } from "../../interface";
import { useAppSelector } from "../../store/store";

const ProfileSettings: React.FC = () => {
  // get user info from Redux store
  const _user = useAppSelector((state) => state.user_info);

  const [user] = useState<UserInfo | null>(_user);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    // add overflow to the container

    <Grid item xs={12}>
      {/* image for the profile */}
      <img
        src={user?.img_url}
        alt="Profile"
        style={{ width: "100px", height: "100px", borderRadius: "50%" }}
      />
      <Typography variant="h6">{user?.user_name}</Typography>
    </Grid>
  );
};

export default ProfileSettings;
