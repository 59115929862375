// spritz.js
// A JavaScript Speed Reader
// rich@gun.io
// https://github.com/Miserlou/OpenSpritz

// Please don't abuse this.
var readability_token = "172b057cd7cfccf27b60a36f16b1acde12783893";
var diffbot_token = "2efef432c72b5a923408e04353c39a7c";

var currentWord = 0;
var running = true;
var spritz_timers = new Array();

var all_words = [];
var ms_per_word = 60000 / 200;

// Entry point to the beef.
// Gets the WPM and the selected text, if any.
function spritz(text, wpm) {
  if (wpm < 1) {
    return;
  }

  if (text) {
    // console.log("Text Received")
    spritzify(text, wpm);
  } else {
    console.log("No Text Received");
    alert("No Text Received");
  }
}

// The meat!
function spritzify(input, wpm) {
  ms_per_word = 60000 / wpm;

  // Split on any spaces.
  all_words = input.split(/\s+/);

  // text_content = text_content.replace(/\./g, ". "); // Make sure punctuation is apprpriately spaced.
  // text_content = text_content.replace(/\?/g, "? ");
  // text_content = text_content.replace(/\!/g, "! ");

  // The reader won't stop if the selection starts or ends with spaces
  if (all_words[0] == "") {
    all_words = all_words.slice(1, all_words.length);
  }

  if (all_words[all_words.length - 1] == "") {
    all_words = all_words.slice(0, all_words.length - 1);
  }

  var word = "";
  var result = "";

  // Preprocess words
  var temp_words = all_words.slice(0); // copy Array
  var t = 0;

  for (var i = 0; i < all_words.length; i++) {
    if (all_words[i].indexOf(".") != -1) {
      temp_words[t] = all_words[i].replace(".", "&#8226;");
    }

    // Double up on long words and words with commas.
    if (
      (all_words[i].indexOf(",") != -1 ||
        all_words[i].indexOf(":") != -1 ||
        all_words[i].indexOf("-") != -1 ||
        all_words[i].indexOf("(") != -1 ||
        all_words[i].length > 8) &&
      all_words[i].indexOf(".") == -1
    ) {
      temp_words.splice(t + 1, 0, all_words[i]);
      temp_words.splice(t + 1, 0, all_words[i]);
      t++;
      t++;
    }

    // Add an additional space after punctuation.
    if (
      all_words[i].indexOf(".") != -1 ||
      all_words[i].indexOf("!") != -1 ||
      all_words[i].indexOf("?") != -1 ||
      all_words[i].indexOf(":") != -1 ||
      all_words[i].indexOf(";") != -1 ||
      all_words[i].indexOf(")") != -1
    ) {
      temp_words.splice(t + 1, 0, " ");
      temp_words.splice(t + 1, 0, " ");
      temp_words.splice(t + 1, 0, " ");
      t++;
      t++;
      t++;
    }

    t++;
  }

  all_words = temp_words.slice(0);

  // document
  //   .getElementById("spritz_toggle")
  //   .addEventListener("click", function () {
  //     if (running) {
  //       stopSpritz();
  //     } else {
  //       startSpritz();
  //     }
  //   });

  // startSpritz();
}

function updateValues(i) {
  var p = pivot(all_words[i]);
  if (document.getElementById("spritz_result"))
    document.getElementById("spritz_result").innerHTML = p;
  currentWord = i;
}

function startSpritz(new_ms_per_word) {
  if (new_ms_per_word) {
    ms_per_word = new_ms_per_word;
  }
  running = true;

  spritz_timers.push(
    setInterval(function () {
      updateValues(currentWord);
      currentWord++;
      if (currentWord >= all_words.length) {
        currentWord = 0;
        stopSpritz();
      }
    }, ms_per_word),
  );
}

function stopSpritz() {
  for (var i = 0; i < spritz_timers.length; i++) {
    clearTimeout(spritz_timers[i]);
  }
  running = false;
}

// Find the red-character of the current word.
function pivot(word) {
  var length = word.length;

  var bestLetter = 1;
  switch (length) {
    case 1:
      bestLetter = 1; // first
      break;
    case 2:
    case 3:
    case 4:
    case 5:
      bestLetter = 2; // second
      break;
    case 6:
    case 7:
    case 8:
    case 9:
      bestLetter = 3; // third
      break;
    case 10:
    case 11:
    case 12:
    case 13:
      bestLetter = 4; // fourth
      break;
    default:
      bestLetter = 5; // fifth
  }

  word = decodeEntities(word);
  var start =
    ".".repeat(11 - bestLetter) +
    word.slice(0, bestLetter - 1).replace(".", "&#8226;");
  var middle = word.slice(bestLetter - 1, bestLetter).replace(".", "&#8226;");
  var end =
    word.slice(bestLetter, length).replace(".", "&#8226;") +
    ".".repeat(11 - (word.length - bestLetter));

  var result;
  result = "<span class='spritz_start'>" + start;
  result = result + "</span><span class='spritz_pivot'>";
  result = result + middle;
  result = result + "</span><span class='spritz_end'>";
  result = result + end;
  result = result + "</span>";

  result = result.replace(/\./g, "<span class='invisible'>.</span>");

  return result;
}

//////
// Helpers
//////

// This is a hack using the fact that browers sequentially id the timers.
function clearTimeouts() {
  var id = window.setTimeout(function () {}, 0);

  while (id--) {
    window.clearTimeout(id);
  }
}

// Let strings repeat themselves,
// because JavaScript isn't as awesome as Python.
String.prototype.repeat = function (num) {
  if (num < 1) {
    return new Array(Math.abs(num) + 1).join(this);
  }
  return new Array(num + 1).join(this);
};

function decodeEntities(s) {
  var str,
    temp = document.createElement("p");
  temp.innerHTML = s;
  str = temp.textContent || temp.innerText;
  temp = null;
  return str;
}

export const sprt = {
  spritz,
  spritzify,
  // pivot,
  // getSelectionText,
  // clearTimeouts,
  // decodeEntities,
  startSpritz,
  stopSpritz,
};
