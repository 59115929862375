import React from "react";
import Spritz from "./glance/spritz";
import TypeComponent from "../type";

export const SpritzWrapper = () => {
  const [spritz, setSpritz] = React.useState(false);
  return (
    <div className=" absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-10">
      <div className="z-50 rounded-lg bg-white p-2">
        {/* tootlge buton for spriz or not */}
        <button onClick={() => setSpritz(!spritz)}>Toggle Spritz Mode</button>
        <br />
      </div>
      <div className="z-50 rounded-lg bg-white bg-opacity-100 p-2">
        {spritz ? <Spritz /> : <TypeComponent />}
      </div>
    </div>
  );
};
