import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../store/store";

const site = "https://admin-io-backend.netlify.app/";

let vercel_site;
    // check for the dev environment 
if (process.env.NODE_ENV === "development") {
  vercel_site = "http://localhost:5000/"; 
}
else {
  vercel_site = "https://admin-io-vercel.vercel.app/";
}

// vercel_site = "http://localhost:5000/";
const backend_addresses = {
  // login: site + ".netlify/functions/server/auth/login",

  new_leetcode_add: site + ".netlify/functions/server/leetcode/new",
  leetcode_list: site + ".netlify/functions/server/leetcode/list",
  leetcode_del: site + ".netlify/functions/server/leetcode/del",
  leetcode_update: site + ".netlify/functions/server/leetcode/update",

  custom_data: site + ".netlify/functions/server/custom/list",
  custom_update: site + ".netlify/functions/server/custom/update",

  users_list: site + ".netlify/functions/server/users/list",

  new_project: site + ".netlify/functions/server/projects/new",
  projects_list: site + ".netlify/functions/server/projects/list",
  project_update: site + ".netlify/functions/server/projects/update",
  project_del: site + ".netlify/functions/server/projects/del",

  // new api call routing
  // profile: site + ".netlify/functions/server/profile/",
  oxford: "https://od-api.oxforddictionaries.com/api/v2",

  twitter_trends: site + ".netlify/functions/server/twitter/trendslist",

  // New vercel APIs
  login: vercel_site + "auth/login",
  splash: vercel_site + "splash/",
  profile: vercel_site + "profile/",
  ritual: vercel_site + "ritual/",
  custom: vercel_site + "custom/",
  story: vercel_site + "story/",
  link: vercel_site + "link/",
  storage: vercel_site + "google/storage/",
  metadata: vercel_site + "metadata/",
  search: vercel_site + "search/",
  dict: vercel_site + "dict/",
  today: vercel_site + "today/",
  task: vercel_site + "tasks/",
  streak: vercel_site + "streak/",
  users: vercel_site + "users/",
};

/**
 * take api data transfer requests and send it through axios request.
 *
 * @param {string} type  type of req [ new / list / del / update/ custom_Special]
 * @param {string} page  Page section action
 * @param {string | null} token Authentication token
 * @param {object | null} info data to be sent
 * @param {object} headers  additional headers to be added
 * @return {Array | Promise<any> } returns response.data or [] ( most likely use .then(m => m === "OK" ? ))
 */
const vinanti = async (
  type, // type of req [ new / list / del / update/ custom_Special]
  page, // Page section action
  token = null, // authentication required
  info = null, // data in body to be sent with,
  headers = {}, // json of headers to be added,
) => {
  if (!token) token = store.getState().user_info.auth_token;
  if (!info) info = { email: store.getState().user_info.user_email };
  else {
    info = { ...info, email: store.getState().user_info.user_email };
  }
  try {
    const { data, status } = await axios.post(
      backend_addresses[page] + type,
      info,
      {
        headers: {
          "auth-token": token,
          ...headers,
        },
      },
    );

    if (status === 200) {
      toast.success(`${page} >> ${type}`);
      return data;
    } else {
      toast.error(`${page} >> ${type}`);
      return null;
    }
  } catch (err) {
    console.log(err);
    toast.error(`${page} >> ${type} >> Axios Error`);
    return null;
  }
};

/**
 * take api data transfer requests and send it through axios request.
 *
 * @param {string} type  type of req [ new / list / del / update/ custom_Special]
 * @param {string} page  Page section action
 * @param {any} body data to be sent
 * @param {object} headers  additional headers to be added
 * @return {Array | Promise<any> } returns response.data or [] ( most likely use .then(m => m === "OK" ? ))
 */
const vinanti_bodyForm = async (
  type, // type of req [ new / list / del / update/ custom_Special]
  page, // Page section action
  body = null, // data in body to be sent with,
  headers = {}, // json of headers to be added,
) => {
  let token = store.getState().user_info.auth_token;

  if (body) body.append("email", store.getState().user_info.user_email);
  else {
    body = { email: store.getState().user_info.user_email };
  }
  try {
    const { data, status } = await axios.post(
      backend_addresses[page] + type,
      body,
      {
        headers: {
          "auth-token": token,
          ...headers,
        },
      },
    );

    if (status === 200) {
      toast.success(`${page} >> ${type}`);
      return data;
    } else {
      toast.error(`${page} >> ${type}`);
      return null;
    }
  } catch (err) {
    console.log(err);
    toast.error(`${page} >> ${type} >> Axios Error`);
    return null;
  }
};

const splash_imgae = async (
  type = "image", // type of req [ new / list / del / update/ custom_Special]
  page = "splash", // Page section action
  token, // authentication required
  data = null, // data in body to be sent with,
  headers = {}, // json of headers to be added,
) => {
  const res = await axios
    .get(backend_addresses[page] + type, data, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  // console.log(`Vinanti >> ${page} >> ${type} >> res`, res);
  toast.success(`${page} >> ${type}`);
  return res ? res.data : [];
};

const newLeetcode = async (
  token,
  problem_no,
  problem_title,
  user,
  level,
  tags,
) => {
  var data = {
    problem_no: problem_no,
    email: user,
    problem_title: problem_title,
    level: level,
    tags: tags,
  };
  // console.log("here for process.:", token, data);
  const res = await axios
    .post(backend_addresses.new_leetcode_add, data, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  // console.log("from data js ",res);
  return res;
};

const newProject = async (
  token,
  project_name,
  project_details,
  user,
  date_expire,
  status,
  milestones,
  assigned_to,
) => {
  var data = {
    project_name: project_name,
    email: user,
    project_details: project_details,
    milestones: [],
    date_expire: date_expire,
    status: status,
    assigned_to: assigned_to,
  };
  // console.log("here for process.:", token, data);
  const res = await axios
    .post(backend_addresses.new_project, data, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  // console.log("from data js ",res);
  return res;
};

const projectsList = async (token, user) => {
  var data = {
    email: user,
  };
  // console.log("Task List .:", token, data);
  const res = await axios
    .post(backend_addresses.projects_list, data, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      // console.log('new topic sent',res);
      return res ? res : null;
    })
    .catch((err) => {
      console.error(err);
    });
  // console.log("Task List :  ", res.data);
  return res ? res.data : null;
};

const leetcodeList = async (token, user) => {
  var data = {
    email: user,
  };
  // console.log("Task List .:", token, data);
  const res = await axios
    .post(backend_addresses.leetcode_list, data, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      // console.log('new topic sent',res);
      return res ? res : null;
    })
    .catch((err) => {
      console.error(err);
    });
  // console.log("Task List :  ", res.data);
  return res ? res.data : null;
};

const ttrendingList = async (location) => {
  var data = {
    location: location,
  };
  // console.log("Task List .:", token, data);
  const res = await axios
    .post(backend_addresses.twitter_trends, data, {
      headers: {},
    })
    .then((res) => {
      console.log("twitter", res);
      return res ? res : null;
    })
    .catch((err) => {
      console.error(err);
    });
  // console.log("Task List :  ", res.data);
  return res ? res.data[0] : null;
};

const Login = async ({ sub, name, email, picture }) => {
  const res = await axios.post(backend_addresses.login, {
    google_id: sub,
    name,
    email,
    img_url: picture,
  });
  // console.log(" Data >> Login >> ", res.data.login);
  return res;
};

const updateProject = async (Project, token) => {
  const res = await axios
    .post(
      backend_addresses.project_update,
      { updated_info: Project },
      {
        headers: {
          "auth-token": token,
        },
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  console.log("Update Res :  ", res);
  return res.data;
};
const updateLeetcode = async (Leetcode, token) => {
  const res = await axios
    .post(
      backend_addresses.leetcode_update,
      { updated_info: Leetcode },
      {
        headers: {
          "auth-token": token,
        },
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  console.log("Update Res :  ", res);
  return res ? res.data : [];
};

const update_custom_settings = async (data, token, email) => {
  const res = await axios
    .post(
      backend_addresses.custom_update,
      { updated_info: data, email: email },
      {
        headers: {
          "auth-token": token,
        },
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  console.log("Update Res :  ", res);
  return res.data;
};

const deleteProject = async (project_id, token, user) => {
  const res = await axios
    .post(
      backend_addresses.project_del,
      { project_id: project_id, user: user },
      {
        headers: {
          "auth-token": token,
        },
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  console.log("Deletion Res :  ", res);
  return res.data;
};
const deleteLeetcode = async (leetcode_id, token, user) => {
  const res = await axios
    .post(
      backend_addresses.leetcode_del,
      { leetcode_id: leetcode_id, user: user },
      {
        headers: {
          "auth-token": token,
        },
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  console.log("Deletion Res :  ", res);
  return res.data;
};
export {
  Login,
  ttrendingList,
  // searchQuery,
  projectsList,
  newProject,
  updateProject,
  deleteProject,
  leetcodeList,
  newLeetcode,
  deleteLeetcode,
  updateLeetcode,
  // custom_settings_data,
  update_custom_settings,
  vinanti,
  splash_imgae,
  vinanti_bodyForm,
};
