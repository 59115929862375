import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { vinanti } from "../../data/data.js";

import { useSelector } from "react-redux";
import { Task } from "./task.tsx";
import {
  getDayNum,
  getWeek,
  get_date,
} from "../../components/utils/general_utils.js";
import { Button } from "react-utility-yard";
import { TaskSchema, TypePayloadAction } from "../../type";
import { RootState, useAppDispatch } from "../../store/store.ts";
import { tasks_update_list } from "../../store/actions/actionTypes.ts";
import { TaskAdder } from "./task_adder.tsx";
import {
  filterTasks,
  sortTasks,
  TaskSorterPanel,
} from "./task_sorter_panel.tsx";
// import { Planby } from "./planner/planby.tsx";

type TasksProps = {
  view: "normal" | "mini";
  taskAdderOn?: boolean;
};

export const Tasks = ({ view = "normal", taskAdderOn = true }: TasksProps) => {
  const [curr_list, setCurr_list] = useState<TaskSchema[] | null>(null);
  const [live_time, setLive_time] = useState(
    `${get_date(
      Date.now(),
      true,
    )}  ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
  );

  const list = useSelector((state: RootState) => state.tasks.list);

  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );

  const get_tasks_List = async () => {
    let data = await vinanti("list", "task", null, {});
    if (data && data.length > 1) {
      let new_data = [
        ...data.sort(
          (a: TaskSchema, b: TaskSchema) => Number(a.status) - Number(b.status),
        ),
      ];
      dispatch_action({ type: tasks_update_list, payload: new_data });
      return new_data;
    }
    return null;
  };

  const filter_n_sort_tasks = async (filters: any, sortings: any) => {
    let filtered_tasks = filterTasks(list, filters);
    let sorted_tasks = sortTasks(filtered_tasks, sortings);
    setCurr_list(sorted_tasks);
  };

  const auto_schedule = async () => {};

  useEffect(() => {
    if (!list?.length) {
      get_tasks_List();
    }

    const timer = setInterval(() => {
      setLive_time(
        `⌚ ${get_date(
          Date.now(),
          true,
        )}  [ ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()} ] `,
      );
    }, 1 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!list?.length) {
      get_tasks_List();
    }
    setCurr_list(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <>
      <div className="flex h-full w-full rounded-xl p-2">
        {/* <div className="max-w-2 h-full w-2 bg-red-500"> &nbsp; </div> */}
        <div className="border-1 mx-auto h-full max-h-full w-full max-w-[800px] rounded-lg bg-blend-color-burn backdrop-blur-md">
          <div className="border-1 flex h-full max-h-full flex-col rounded-lg text-center">
            <div className="flex flex-col items-center gap-2 p-2">
              <div className="w-fit rounded-lg bg-slate-200 px-2">{`${live_time} 
                     [week ${getWeek()}] [day ${getDayNum()}]
                     `}</div>
              <ul className="flex flex-row items-center gap-2 px-2">
                <div className="flex flex-col items-center gap-2">
                  <Button text="Get Tasks" onclick={get_tasks_List} />
                  <Button  text="Schedule" onclick={auto_schedule} />
                </div>

                <TaskSorterPanel onFilterNSort={filter_n_sort_tasks} />
              </ul>
            </div>
            <div className="flex h-full flex-grow flex-col gap-2 overflow-auto p-2">
              <div className="">{taskAdderOn && <TaskAdder />}</div>
              <div className="flex flex-grow overflow-auto">
                <ul className="mx-1 flex h-full w-full flex-col gap-1 overflow-y-auto pr-2">
                  {curr_list ? (
                    curr_list.map((d, i) => <Task task={d} view={view} />)
                  ) : (
                    <li className="my-1">No Tasks Found.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
