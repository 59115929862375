import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { DeviceToken, UserInfo } from "../../interface";
import { useAppSelector } from "../../store/store";
import { vinanti } from "../../data/data";
import { MaterialIcon } from "react-utility-yard";
import { fetchToken } from "../../add-ons/notifications/firebase";

const NotificationSettings: React.FC = () => {
  const _user = useAppSelector((state) => state.user_info);
  const [user] = useState<UserInfo | null>(_user);
  const [token_info, setTokenInfo] = useState<DeviceToken[] | null>(null);

  const getTokenInfo = async () => {
    try {
      const data = await vinanti("device_tokens", "users");
    //   console.log("Token Info:", data[0].device_tokens);
      if (data.length > 0) setTokenInfo(data[0].device_tokens);
    } catch (error) {
      console.error("Error fetching token info:", error);
    }
  };

  const register_device = async () => {
    try {
      await fetchToken(user?.user_email);
      getTokenInfo();
    } catch (error) {
      console.error("Error registering device:", error);
    }
  };

  const pingToken = (token: string) => {
    console.log("Ping token:", token);
  };

  const deleteToken = async (token: string) => {
    try {
      await vinanti("delete_token", "users", null, {
        token,
        email: user?.user_email,
      });
      getTokenInfo();
    } catch (error) {
      console.error("Error deleting token:", error);
    }
  };

  useEffect(() => {
    getTokenInfo();

    return () => {};
  }, []);

  return (
    // add overflow to the container
    <Box mt={4}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6">Notifications</Typography>
          <Button variant="contained" color="primary" onClick={register_device}>
            Register This Device
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                // checked={isNotificationEnabled}
                // onChange={handleNotificationToggle}
                name="notificationSwitch"
                color="primary"
              />
            }
            label="Enable Notifications"
          />
        </Grid> */}
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          {
            <Box
              sx={{
                overflowX: "auto",
                p: 2,
                // mt: 2,
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Notification Token Information
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getTokenInfo}
                >
                  Get Token Info
                </Button>
              </Box>
              {token_info &&
                token_info.map((token, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: 2,
                      mb: 2,
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography variant="body1">
                        Device Type: {token.device_type}
                      </Typography>
                      <Box>
                        <MaterialIcon
                          icon="ping"
                          onClick={() => pingToken(token.token)}
                        />
                        {/* Delete Icon */}
                        <MaterialIcon
                          cursor="pointer"
                          icon="delete"
                          onClick={() => deleteToken(token.token)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography variant="body2">Token:</Typography>
                      <Box
                        sx={{
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                          p: 1,
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                          backgroundColor: "#f1f1f1",
                        }}
                      >
                        <Typography variant="body2">{token.token}</Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotificationSettings;
